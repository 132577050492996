<template>
  <!-- Form -->
  <van-form ref="authForm" class="mt-4">
    <van-cell-group inset>
      <!-- Alert -->
      <div v-if="message" class="alert alert-danger">{{ message }}</div>
      <!-- Country Code -->
      <it-picker :placeholder="t('placeholder.country-code')" :value="MobileFilter.region(auth.region, phoneCountryCodes)" :options="pickerOptions" @confirm="onConfirm" :rules="[{ required: true, message: t('validator.invalidate-country-code') }]"></it-picker>
      <!-- Mobile -->
      <van-field v-model.trim="auth.mobile" name="mobile" :placeholder="t('placeholder.mobile')" label-width="50" clearable :rules="[{ validator: mobileValidator, trigger: 'onBlur' }]"></van-field>
      <!-- OTP -->
      <van-field type="digit" v-model.trim="auth.otp" name="otp" clearable :placeholder="t('placeholder.otp')" :rules="[{ validator: otpValidator, trigger: 'onBlur' }]">
        <template #button>
          <van-button size="small" type="primary" @click="doSendAuthOtp" style="width: 100px;" :loading="sending" :disabled="counting">
            <van-count-down v-if="counting" :time="60 * 1000" format="ss" @finish="counting = false" />
            <span v-else>{{ t('button.get-otp') }}</span>
          </van-button>
        </template>
      </van-field>
    </van-cell-group>

    <!-- Button -->
    <div class="mx-4 my-4">
      <van-button round block type="primary" :loading="authing" @click="doAuth">{{ t('button.auth') }}</van-button>
      <div class="mt-4 text-center" style="color: red;">{{ t('note.signup-reward') }}</div>
    </div>
  </van-form>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { FormInstance, PickerOption, showFailToast, showSuccessToast } from 'vant'
import { onMounted, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import authAPI from '../../api/auth'
import mobileAPI from '../../api/mobile'
import smsAPI from '../../api/sms'
import { MobileFilter } from '../../filters/mobileFilter'
import { Actions, useStore } from '../../store'
import { ParentAuthed, ParentMobileOtpAuth } from '../../types/auth'
import { Result } from '../../types/common/result'
import { PhoneCountryCode } from '../../types/mobile'
import { getCodeFromRegion } from '../../utils/mobileUtils'
import { mobileValidator, otpValidator } from '../../validators'
import ItPicker from '../ui/ItPicker.vue'


const { t, locale } = useI18n({
  useScope: 'global'
})

const route = useRoute()
const router = useRouter()
const store = useStore()

const phoneCountryCodes = ref<PhoneCountryCode[]>([])
const pickerOptions = ref<PickerOption[]>([])
const doConvertPhoneCountryCodes = function (phoneCountryCodes: PhoneCountryCode[]): PickerOption[] {
  let pickerOptions: PickerOption[] = []
  phoneCountryCodes.forEach(phoneCountryCode => {
    pickerOptions.push({ text: phoneCountryCode.country, value: phoneCountryCode.region })
  })
  return pickerOptions
}

const onConfirm = function(selectedOptions: (PickerOption | undefined)[]) {
  auth.region = selectedOptions[0]?.value as string
  auth.countryCode = getCodeFromRegion(phoneCountryCodes.value, auth.region)
}

// 查找国家区号代码
const doFindAllPhoneCountryCodes = function () {
  mobileAPI.findAllCountryCode(store.getters.locale).then((response: AxiosResponse<Result<PhoneCountryCode[]>>) => {
    const result = response.data
    if (result.success) {
      phoneCountryCodes.value = result.data
      pickerOptions.value = doConvertPhoneCountryCodes(phoneCountryCodes.value)
    } else {
      showFailToast(result.message)
    }
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  })
}

const message = ref<string>('')
const auth = reactive<ParentMobileOtpAuth>(new ParentMobileOtpAuth())
const authForm = ref<FormInstance>()

// 发送验证码
const counting = ref<boolean>(false)
const sending = ref<boolean>(false)
const doSendAuthOtp = function() {
  authForm.value?.validate(['countryCode', 'mobile']).then(() => {
    if (auth.countryCode && auth.mobile) {
      sending.value = true
      smsAPI.sendAuthOtp(auth.countryCode, auth.mobile).then((response: AxiosResponse<Result<void>>) => {
        const result = response.data
        if (result.success) {
          counting.value = true
          showSuccessToast(t('message.send-otp-success'))
        } else {
          message.value = result.message
        }
      }).catch((error: AxiosError) => {
        showFailToast(error.message)
      }).finally(() => {
        sending.value = false
      })
    }
  }).catch(() => {})
}

// 认证
const authing = ref<boolean>(false)
const doAuth = function() {
  authForm.value?.validate().then(() => {
    authing.value = true
    // 登录或注册
    auth.promoteCode = store.getters.promoteCode
    authAPI.mobileOtpAuth(auth).then((response: AxiosResponse<Result<ParentAuthed>>) => {
      const result = response.data
      if(result.success) {
        const authed = result.data
        const parent = authed.parent
        if (parent.locked) { // 账户锁定
          message.value = t('message.account-is-locked')
        } else {
          // 存储家长信息
          store.dispatch(Actions.AUTH, authed).then(() => {
            if (parent.complete) {
              // 同步语言
              if (locale.value !== parent.locale) locale.value = parent.locale
                store.dispatch(Actions.CHANGE_LOCALE, parent.locale)
                // 同步时区
                store.dispatch(Actions.CHANGE_ZONE_ID, parent.zoneId)
                // 跳转
                if(route.params.to) {
                  router.push({name: route.params.to as string})
                } else {
                  router.go(-1)
                }
            } else {
              router.push({
                name: 'supplement'
              })
            }
          })
        }
      } else {
        message.value = result.message
      }
    }).catch((error: AxiosError) => {
      const response = error.response
      if (response) {
        if (response.status === 400) {
          message.value = t('message.otp-is-wrong')
        } else {
          message.value = error.message
        }
      } else {
        showFailToast(error.message)
      }
    }).finally(() => {
      authing.value = false
    })

  }).catch(() => {})
}

onMounted(() => {
  doFindAllPhoneCountryCodes()
})
</script>